.username-status-icon {
    display: flex;
    justify-content: space-between;
}

.user-editor-container {
    
    label.required:after {
        content: "*";
    }

    &.transfer-team {
        .knock-react-modal {
            width: 600px;
        }

        label {
            font-weight: 600;
            
            input {
                margin-left: 10px;
            }
        }

        .transfer-error {
            color: #F44336;
            margin: 0 auto;
            width: 460px;
        
            .message {
                padding: 0 10px;
            }

            .title {
                font-weight: 600;
            }
        }
    }

}
